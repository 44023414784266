<template>
  <div class="main">
    <heads :states="states" :more="showMore" :msg="title_msg"></heads>
    <div class="chat-card">
      <div class="chat-content">
        <div v-for="(item,index) in list" :key="index" class="content-main">
          <div class="picture">
            <img :src="item.type == 1 ? 'https://newoss.zhulong.com/forum/202206/02/46/103546ckyxy5lcwwkes8du.png':avator" alt="" onerror="javascript:this.src='https://newoss.zhulong.com/tfs/noavatar_big.gif'">
            <p v-if="item.type == 1">小鱼儿</p>
            <!-- <p v-else>用户名</p> -->
          </div>
          <div class="content-body">
            <div>
              <div v-if="item.type==1" class="message incoming">
                <div v-html="item.content"></div>
              </div>
              <div v-if="item.type==2" class="message outgoing">
                <p>{{ item.content }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="btn" @click="toGPT">
        前往筑龙知识问答助手
      </div>
    </div>
  </div>
</template>
<script>
import Cookie from 'js-cookie'
import wx from 'weixin-js-sdk'
import WechatState from '@/utils/isWechatState.js'
import MarkdownIt from 'markdown-it'
import hljs from 'highlight.js'
import heads from '@/compontens/heads.vue'
import { getShare } from '@/api/chatGPT.js'
export default {
  components: {
    heads
  },
  data() {
    return {
      states: 1,
      showMore: true,
      title_msg: 'AI建筑问答',
      list: [],
      shareId: this.$route.query.shareId
    }
  },
  mounted() {
    this.getShare()
  },
  methods: {
    getShare() {
      const info = {
        shareId: this.shareId
      }
      getShare(info).then(res => {
        this.list = [
          {
            type: 2,
            content: res.result.answer
          },
          {
            type: 1,
            content: this.renderMarkdown(res.result.ask)
          }
        ]
      })
    },
    // markdown渲染，highlight.js高亮
    renderMarkdown(text) {
      const md = new MarkdownIt({
        highlight: function(str, lang) {
          if (lang && hljs.getLanguage(lang)) {
            try {
              return `<pre class="hljs"><code>${hljs.highlight(str, { language: lang }).value}</code></pre>`
            } catch (__) {}
          }

          return `<pre class="hljs"><code>${md.utils.escapeHtml(str)}</code></pre>`
        }
      })
      return md.render(text)
    },
    toGPT() {
      this.$router.push({
        path: '/standardSeach'
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.chat-card {
  width: 670px;
  margin: 21px auto 0;
  display: flex;
  flex-flow: column;
}
.picture{
  display: flex;
  align-items: center;
  img{
    width: 46px;
    border-radius: 10px;
    margin-right: 10px;
  }
  p{
    font-size: 32px;
  }
}
.chat-content{
  padding-bottom: 310px;
  .content-main{
    margin-bottom: 17px;
  }
  .content-body{
    width: 560px;
    margin: 10px 0 0 60px;
    border-radius: 10px;
    background: rgba(247, 247, 247, 1);
    padding: 20px 21px 20px;
    position: relative;
    .message {
      font-size: 30px;
      font-weight: 500;
      letter-spacing: 0px;
      line-height: 43px;
      color: rgba(68, 68, 68, 1);
    }
    .incoming {
      .copy{
        width: 560px;
        margin: 10px auto 0;
        border-top: 2px solid rgba(218, 218, 218, 1);
        text-align: right;
        margin-right: 20px;
        .btn{
          margin-top: 20px;
          display: inline-block;
          cursor: pointer;
          img{
            width: 32px;
            vertical-align: text-top;
          }
          .img2{
            width: 40px;
            margin-top: -4px;
          }
          .sline{
            margin: 0 20px;
            color: #999;
            display: inline-block;
            height: 30px;
            border-right: 1px solid rgba(205, 205, 205, 1);
          }
        }
      }
    }
    .outgoing {

    }
    .tip{
      position: absolute;
      right: 0;
      bottom: 0;
      margin-bottom: -32px;
      color: #ee2e2e;
    }
  }
}
.bottom{
  width: 100%;
  background: #fff;
  position: fixed;
  bottom: 0;
  padding: 50px 0 100px;
  .btn{
    width: 286px;
    height: 70px;
    margin: 0 auto;
    border-radius: 32px;
    background: #D71616;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 70px;
    color: rgba(255, 255, 255, 1);
    text-align: center;
  }
}
</style>
